<template>
  <div class="app">

    <main
      :style="'--c-prim:var(--c-' + ($store.state.currentPage ? $store.state.currentPage.meta.color : '') + ')'"
    >
      <template v-if="Array.isArray(page.content)">
        <component
          v-for="(component, index) in page.content"
          :key="index"
          :is="findComponent(component)"
          :payload="component.data || {}"
        />
      </template>
      <template v-else>
        <component
          :is="findComponent(page.content)"
          :payload="page.content.data || {}"
        />
      </template>
    </main>

    <Footer />
  </div>
</template>
<style lang="scss">
@import "~~/assets/scss/_vars.scss";
@import "~bootstrap/scss/mixins";

$part-spacing: 30px;

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;

  .filled + .filled {
    margin-top: 0 !important;
  }
}
</style>

<script>
import Vue from 'vue';
import he from 'he';

// auto register components
const requireComponent = require.context('@/components', true, /.*$/);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');
  Vue.component(componentName, componentConfig.default || componentConfig);
});

export default {
  data() {
    return {
      page: this.$store.state.currentPage,
      hasContent: false,
    };
  },

  head() {
    if (!this.$store.state.currentPage) {
      return;
    }
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      title: he.decode(this.$store.state.currentPage.meta.title),
      titleTemplate: (titleChunk) => `${this.$route.path.length < 4 ? titleChunk : `${titleChunk} - Herzogenmühle`}`,
      meta: [

        // seo meta
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.currentPage.meta.description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$store.state.currentPage.meta.keywords,
        },

        // social meta
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.$store.state.currentPage.meta.image,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$store.state.currentPage.meta.title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$store.state.currentPage.meta.description,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Herzogen',
        },

      ],
    };
  },

  methods: {
    overlayUpdate(newContent) {
      this.hasContent = newContent;
    },
    findComponent(component) {
      if (component && Vue.options.components[component.component]) { return component.component; }
      return 'BASE';
    },
  },
};
</script>
