<template>
  <div class="margin-section">
  <div class="main-container">
    <h2 class="bold text-center" v-html="payload.title" />
    <div v-if="payload.karten">
      <div class="row">
        <div
          class="col-lg-6 padding-sides-cards"
          v-for="karte in payload.karten"
          :key="karte.title"
        >
          <Bild-Text-Liste-Karte
            :imgSrc="karte.bild.normal"
            :title="karte.title"
            :text="karte.text"
          />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: { type: Object, default: Object },
    options: { type: Object, default: Object },
  },
};
</script>

<style lang="scss" scoped>
@import "~~include-media/dist/include-media";
.margin-image-text-list {
  margin-top: 235px;
  margin-bottom: 90px;
}
</style>
