// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.pagination-container[data-v-6a5bac8c][data-v-8c1f4b80]{top:-6px!important}.padding-pdf-viewer[data-v-8c1f4b80]{padding-top:52px;padding-bottom:42px}.margin-bottom-title[data-v-8c1f4b80]{margin-bottom:80px}.margin-image-swiper[data-v-8c1f4b80]{margin-top:201px;margin-bottom:182px}.image-styles[data-v-8c1f4b80]{width:100%;height:719px;-o-object-fit:cover!important;object-fit:cover!important}@media (max-width:768px){.image-styles[data-v-8c1f4b80]{height:300px}}.swiper[data-v-8c1f4b80]{margin:50px 0}.margin-top-caption[data-v-8c1f4b80]{margin-top:30px}@media (min-width:992px){.margin-top-caption[data-v-8c1f4b80]{margin-top:30px}}.icon-styles[data-v-8c1f4b80]{width:14px;height:14px}.pagination-container[data-v-8c1f4b80]{position:relative;top:10px;z-index:1000}@media (min-width:1200px){.pagination-container[data-v-8c1f4b80]{top:-40px}}", ""]);
// Exports
module.exports = exports;
