<template>
  <div :id="payload.slug" class="BASE part container"></div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "@/assets/scss/_mixins.scss";
</style>
