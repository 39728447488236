<template>
  <div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="swiper-slide"
        v-for="image in payload.bilder"
        :key="image.caption"
      >
        <img v-lazy-load :data-src="image.sizes.large" alt="image.alt" class="hero-image" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "swiper-example-pagination",
  title: "Pagination",
  props: {
    payload: { type: Object, default: Object },
    options: { type: Object, default: Object },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        init: this.payload.bilder.length > 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: true,
        autoplay: {
          delay: 3000,
        },
        speed: 1000,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "~~include-media/dist/include-media";
.hero-image {
  width: 100%;
  height: 820px;
  object-fit: cover;

  @include media("<=768px"){
    height: 400px;
  }

}
</style>
