<template>
  <div class="milchbuck-container margin-component">
    <h2
      class="text-center bold padding-title"
      v-if="payload.title"
      v-html="payload.title"
    />
    <div class="row">
      <div class="col-xl-4 margin-bottom-mobile">
        <div class="row">
          <div class="col-12">
            <Gallerie-Foto :index="0" :images="images" />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <Gallerie-Foto :index="1" :images="images" />
          </div>
          <div class="col-6">
            <Gallerie-Foto :index="2" :images="images" />
          </div>
        </div>
      </div>
      <div class="col-xl-4 d-flex flex-column margin-bottom-mobile">
        <div class="row">
          <div class="col-6">
            <Gallerie-Foto :index="3" :images="images" />
          </div>
          <div class="col-6">
            <Gallerie-Foto :index="4" :images="images" />
          </div>
        </div>
        <div class="row flex-grow-1">
          <div class="col-12">
            <div
              class="bg-dark-brown h-100 padding-text d-flex flex-column justify-content-center"
            >
              <h1
                class="text-white align-self-center bold mb-0"
                v-if="payload.text"
                v-html="payload.text"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <Gallerie-Foto :index="5" :images="images" />
          </div>
          <div class="col-6">
            <Gallerie-Foto :index="6" :images="images" />
          </div>
        </div>
      </div>
      <div class="col-xl-4 margin-bottom-mobile">
        <div class="row">
          <div class="col-6">
            <Gallerie-Foto :index="7" :images="images" />
          </div>
          <div class="col-6">
            <Gallerie-Foto :index="8" :images="images" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Gallerie-Foto :index="9" :images="images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
  computed: {
    images() {
      let output = [];
      if (this.payload.gallerie) {
        shuffleArray(this.payload.gallerie);
        const images =
          this.payload.gallerie.length > 10
            ? this.payload.gallerie.slice(0, 10)
            : this.payload.gallerie;
        output = images;
      }
      return output;
    },
  },
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "@/assets/scss/_mixins.scss";
@import "~~include-media/dist/include-media";
.margin-bottom-mobile {
  @include media("<=1200px") {
    margin-bottom: 20px;
  }
}
.padding-text {
  padding: 50px 50px;

  @include media(">=1400px") {
    padding: 0 129px;
  }
}
.padding-title {
  padding-bottom: 120px;
}
.margin-component {
  margin-top: 240px;
  margin-bottom: 200px;
}
.row + .row {
  margin-top: 20px;
}
</style>