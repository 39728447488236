<template>
  <div class="main-container mb-3">
    <img v-lazy-load :data-src="payload.bild.large">
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      default: Object,
    },
    options: {
      type: Object,
      default: Object,
    },
  },
};
</script>

<style scoped>

</style>
