<template>
  <div class="margin-section">
  <div class="main-container">
    <h1 class="bold text-center mb-0" v-html="payload.title" />
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="swiper-slide"
        v-for="image in payload.bilder"
        :key="image.title"
      >
        <img v-lazy-load :data-src="image.sizes.large" class="image-styles" :alt="image.alt" />
        <h3
          class="bold text-dark-brown mb-0 margin-top-caption"
          v-if="image.caption"
          v-html="image.caption"
        />
      </swiper-slide>
      <div
        class="image-swiper swiper-pagination d-none d-lg-block margin-top-pagination"
        slot="pagination"
      ></div>
    </swiper>
    <div
      v-if="payload.bilder.length > 1"
      class="w-100 d-flex justify-content-center pagination-container"
    >
      <div class="swiper-prev d-flex justify-content-center align-items-center">
        <ChevronLeft class="icon-styles" />
      </div>
      <div class="swiper-next d-flex justify-content-center align-items-center">
        <ChevronRight class="icon-styles" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ChevronRight from "~/assets/icons/chevron-right-solid.svg?inline";
import ChevronLeft from "~/assets/icons/chevron-left-solid.svg?inline";

export default {
  props: { payload: { type: Object, default: Object } },
  data() {
    return {
      swiperOption: {
        init: this.payload.bilder.length > 1,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      },
    };
  },
  components: {
    ChevronRight,
    ChevronLeft,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "@/assets/scss/_mixins.scss";
@import "~~include-media/dist/include-media";
.margin-image-swiper {
  margin-top: 201px;
  margin-bottom: 182px;
}
.image-styles {
  width: 100%;
  height: 719px;
  object-fit: cover !important;
  @include media("<=768px") {
    height: 300px;
  }
}
.swiper {
  margin: 50px 0;
}
.margin-top-caption {
  margin-top: 30px;
  @include media(">=992px") {
    margin-top: 30px;
  }
}
.icon-styles {
  width: 14px;
  height: 14px;
}
.pagination-container {
  position: relative;
  top: 10px;
  z-index: 1000;
  @include media(">=1200px") {
    top: -40px;
  }
}
</style>

