<template>
  <div class="main-container">
    <div class="row">
      <Zeitleiste-Horizontal-Meilenstein
        v-for="(step, index) in payload.milestones"
        :key="step.title"
        :imgSrc="step.bild.normal"
        :title="step.title"
        :text="step.text"
        :status="step.status[0]"
        :index="index"
        :textSide="(index + 1) % 2 === 0 ? 'right' : 'left'"
      />
    </div>

    <!-- <div v-if="payload.meilensteine"> -->
    <!-- <div v-for="(step, index) in payload.meilensteine" :key="step.title">
            <ZeitleisteMeilenstein
              :imgSrc="step.bild.normal"
              :title="step.title"
              :text="step.text"
              :status="step.status[0]"
              :index="index"
              :textSide="(index + 1) % 2 === 0 ? 'right' : 'left'"
            />
          </div> -->
    <!-- </div> -->
    <!-- <div class="" />
    <div class="row">
      <div class="col-auto timeline">
        <div class="circle negative-margin-circle">
          <CheckSolid class="padding-icon" />
        </div>
      </div>
      <div class="col-auto timeline">
        <div class="circle circle-current negative-margin-circle"></div>
      </div>
      <div class="col-auto timeline timeline-future">
        <div class="circle circle-future negative-margin-circle"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import CheckSolid from "~/assets/icons/check-solid.svg?inline";

export default {
  props: { payload: { type: Object, default: Object } },

  components: {
    CheckSolid,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
.timeline {
  padding-left: 200px;
  padding-right: 0;
  border-top: 4px solid $dark-brown;
  &-future {
    border-top: 4px solid $dark-white;
  }
}
.circle {
  width: 40px;
  height: 40px;
  border: 4px solid $dark-brown;
  border-radius: 50%;
  background: $medium-brown 0% 0% no-repeat padding-box;
  &-current {
    background: $dark-white 0% 0% no-repeat padding-box;
  }
  &-future {
    background: $white 0% 0% no-repeat padding-box;
    border: 4px solid $dark-white;
  }
}
.padding-icon {
  padding: 6px;
}
.negative-margin-circle {
  margin-top: -24px;
}
</style>