<template>
  <img
    v-if="image"
    v-lazy-load :data-src="image"
    alt=""
    class="img-fluid object-fit-cover"
    :class="{ large: bigImage, small: !bigImage }"
  />
</template>

<script>
export default {
  props: { index: Number, images: Array },
  computed: {
    image() {
      if (this.index > this.images.length - 1) {
        return null;
      }
      return this.images[this.index].sizes.normal;
    },
    bigImage() {
      return this.index === 0 || this.index === 9;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~~include-media/dist/include-media";
.object-fit-cover {
  object-fit: cover;
}
.large {
  width: 100%;
  height: 270px;
  max-height: 270px;
  @include media(">=768px") {
    height: 540px;
    max-height: 540px;
  }
}
.small {
  width: 100%;
  height: 80px;
  max-height: 80px;
  @include media(">=768px") {
    height: 160px;
    max-height: 160px;
  }
}
</style>