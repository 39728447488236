<template>
  <div>
    <img class="image-styles padding-side-text" v-if="imgSrc" v-lazy-load :data-src="imgSrc" />
    <h3 class="bold text-dark-brown mt-2">{{ title }}</h3>
    <div v-if="text" v-html="text" />
    <p class="text-dark-gray" v-if="datum" v-html="datum" />
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: String,
    title: String,
    text: String,
    datum: String,
    index: Number,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "@/assets/scss/_mixins.scss";
.image-styles {
  height: 248px;
  object-fit: cover;
}
</style>
