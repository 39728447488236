<template>
  <div class="margin-section">
    <div class="milchbuck-container">
      <div class="d-flex justify-content-center">
        <div
          class="banner-styling d-flex justify-content-center align-items-center"
        >
          <h3
            class="mb-0 text-white bold"
            v-if="payload.bannertext"
            v-html="payload.bannertext"
          />
        </div>
      </div>
      <div class="bg-dark-white card-styling">
        <div class="main-container main-container-adjustments">
          <div class="row justify-content-between">
            <div class="col-lg-5 align-items-center d-flex">
              <img class="image-styles mb-2 mt-2" v-lazy-load :data-src="payload.bild.normal" />
            </div>
            <div
              class="col-lg-5 offset-lg-2 d-flex flex-row align-items-center"
            >
              <div class="d-flex flex-column">
                <h1
                  class="cta bold text-dark-brown text-center text-lg-left mb-1 mt-1"
                  v-if="payload.title"
                  v-html="payload.title"
                />
                <nuxt-link
                  v-if="payload.buttontext"
                  :to="$store.state.layout.navigation.links[0].uri"
                  class="mt-3 btn-secondary d-flex align-items-center justify-content-center"
                >
                  <h3 class="bold mb-0">
                    {{ payload.buttontext }}
                  </h3>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
@import "~~include-media/dist/include-media";
@import "@/assets/scss/_vars.scss";
.margin-komponente {
  margin-top: 200px;
  margin-bottom: 200px;
}
.image-styles {
  max-height: 208px;
}
.main-container-adjustments {
  @include media(">=1920px") {
    padding: 0 140px !important;
  }
}
.card-styling {
  border-radius: 10px;
  padding: 74px 0;
}
.banner-styling {
  width: 540px;
  height: 80px;
  background: $dark-gray 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: -40px;
}
</style>
