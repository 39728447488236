<template>
  <div class="bg-dark-white margin-section">
    <div class="main-container">
      <div class="padding-section">
        <h2
          class="text-center bold padding-title"
          v-if="payload.title"
          v-html="payload.title"
        />

        <swiper
          v-if="payload.news"
          class="swiper margin-top-swiper"
          :options="swiperOption"
        >
          <swiper-slide
            role="button"
            to="/news/"
            class="swiper-slide"
            v-for="(step, index) in payload.news"
            :key="step.title"
          >
            <router-link :to="step.data.link" tag="div" role="button">
              <Artikel-Liste-Artikel
                :img-src="step.data.bild.normal"
                :title="step.data.title"
                :text="step.data.text"
                :datum="step.data.datum"
                :index="index"
              />
            </router-link>
          </swiper-slide>
        </swiper>
        <div
          v-if="payload.news.length > 3"
          class="w-100 d-flex justify-content-center pagination-container"
        >
          <div
            class="swiper-prev d-flex justify-content-center align-items-center"
          >
            <ChevronLeft class="icon-styles" />
          </div>
          <div
            class="swiper-next d-flex justify-content-center align-items-center"
          >
            <ChevronRight class="icon-styles" />
          </div>
        </div>
        <div class="d-flex justify-content-center padding-button">
          <nuxt-link
            :to="`/${$i18n.locale}/news`"
            class="mt-3 btn-secondary d-flex align-items-center justify-content-center"
          >
            <h3 class="bold mb-0">
              {{ payload.buttontext }}
            </h3>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronRight from '~/assets/icons/chevron-right-solid.svg?inline';
import ChevronLeft from '~/assets/icons/chevron-left-solid.svg?inline';

export default {
  props: { payload: { type: Object, default: Object } },
  data() {
    return {
      swiperOption: {
        init: this.payload.news.length > 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        resistanceRatio: this.payload.news.length <= 3 ? 0 : 0.85,
        slidesPerView: 1,
        breakpoints: {
          992: {
            slidesPerView: 3,
          },
        },
        spaceBetween: 90,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
      },
    };
  },
  components: {
    ChevronRight,
    ChevronLeft,
  },
};
</script>

<style lang="scss" scoped>
.icon-styles {
  width: 14px;
  height: 14px;
}
.padding-title {
  padding-bottom: 30px;
}
.padding-button {
  padding-top: 80px;
}
</style>
