// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.image-styles[data-v-920bc0c8]{height:400px;width:100%;-o-object-fit:cover;object-fit:cover;padding:20px 0}@media (max-width:768px){.image-styles[data-v-920bc0c8]{height:200px}}.timeline[data-v-920bc0c8]{width:4px;height:50%;position:relative;z-index:-1}.timeline-top[data-v-920bc0c8]{top:0}.timeline-bottom[data-v-920bc0c8]{bottom:0}.timeline-done[data-v-920bc0c8]{background-color:#5f3641}.timeline-current[data-v-920bc0c8]{background:#5f3641}.timeline-future[data-v-920bc0c8]{background:#f0f0f0}.padding-icon[data-v-920bc0c8]{padding:6px}.circle[data-v-920bc0c8]{width:40px;height:40px;border:4px solid #5f3641;border-radius:50%;background:#81524c 0 0 no-repeat padding-box}.circle-current[data-v-920bc0c8]{background:#f0f0f0 0 0 no-repeat padding-box}.circle-future[data-v-920bc0c8]{background:#fff 0 0 no-repeat padding-box;border:4px solid #f0f0f0}.timeline-top-line[data-v-920bc0c8]{width:4px;height:90px}.margin-side-timeline[data-v-920bc0c8]{margin-left:auto;margin-right:auto}", ""]);
// Exports
module.exports = exports;
