import { render, staticRenderFns } from "./Plain-Title.vue?vue&type=template&id=7819628c&scoped=true&"
import script from "./Plain-Title.vue?vue&type=script&lang=js&"
export * from "./Plain-Title.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7819628c",
  null
  
)

export default component.exports