<template>
  <div>
    <h2 class="text-center bold">
      {{ payload.title }}
    </h2>
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      default: Object,
    },
    options: {
      type: Object,
      default: Object,
    },
  },
};
</script>

<style scoped>

</style>
