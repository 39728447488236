<template>
  <div class="margin-section">
    <div class="main-container">
      <div
        v-if="payload.text"
        class="mb-0 margin-top-main-text main-text"
        v-html="payload.text"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: { type: Object, default: Object },
    options: { type: Object, default: Object },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "~~include-media/dist/include-media";

.main-text {
  /deep/ p strong {
    font-family: Averta-Bold, sans-serif;
  }

  /deep/ ul li {
    font-size: 18px;
  }
}

.margin-top-main-text {
  margin-top: 43px;
}
</style>
