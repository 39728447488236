// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.margin-top-end-text[data-v-3bd36ca7]{margin-top:20px}.timeline[data-v-3bd36ca7]{width:4px;height:70px}.timeline-done[data-v-3bd36ca7]{background-color:#5f3641}.timeline-current[data-v-3bd36ca7]{background:#5f3641}.timeline-future[data-v-3bd36ca7]{background:#f0f0f0}.timeline-top-line[data-v-3bd36ca7]{width:4px;height:90px}.timeline-starting-point[data-v-3bd36ca7]{width:20px;height:20px;border-radius:50%}.margin-bottom-title[data-v-3bd36ca7]{margin-bottom:100px}.circle[data-v-3bd36ca7]{width:40px;height:40px;border:4px solid #5f3641;border-radius:50%;background:#81524c 0 0 no-repeat padding-box}.circle-current[data-v-3bd36ca7]{background:#f0f0f0 0 0 no-repeat padding-box}.circle-future[data-v-3bd36ca7]{background:#fff 0 0 no-repeat padding-box;border:4px solid #f0f0f0}.padding-icon[data-v-3bd36ca7]{padding:6px}.negative-margin-circle[data-v-3bd36ca7]{margin-top:-24px}", ""]);
// Exports
module.exports = exports;
