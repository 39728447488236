<template>
  <div class="language" :class="{open: open}" v-click-outside="e => close(false)">
    <div class="selection">
      <transition-group tag="ul" name="list" class="list-unstyled">
        <li
          v-for="lang in languages"
          :key="lang"
          class="lang"
          @click="activate(lang)"
        >
          {{ lang }}
        </li>
      </transition-group>
    </div>

    <font-awesome-icon :icon="['far', 'comment-alt']" @click="close(!open)" />
  </div>
</template>

<style scoped lang="scss">
@import "~~/assets/scss/_vars.scss";
@import "~bootstrap/scss/mixins";

$width: 108px;

.language {
  color: #fff;

  position: relative;
  display: inline-block;
  user-select: none;
  width: 1.5em;
  transform: translateY(-8px);

  @include media-breakpoint-down(md) {
    opacity: 0;
    max-width: 0;
    margin-left: 0;
    .active & {
      opacity: 1;
      max-width: 150px;
      margin-left: 15px;
    }
  }

  &:hover svg,
  &:hover .lang:last-of-type {
    color: $primary;
    cursor: pointer;
  }

  &.open {
    width: $width;

    svg,
    .lang:last-of-type {
      color: $primary;
    }
    .selection {
      width: $width;
    }
  }
}

svg {
  position: relative;
  z-index: 10;
}

.selection {
  margin-right: -29.5px;
  transform: translateY(7.5px);
  display: inline-block;
  overflow: hidden;
  width: 25px;
  direction: rtl;
  transition: width 0.4s;
}

ul {
  display: inline-block;
  width: $width;
  direction: ltr;
  margin: 0;
  padding: 0;
  transform: translateY(-8px);
}

.lang {
  display: inline-block;
  font-variant-numeric: tabular-nums lining-nums;
  padding: 0 5px;
  font-size: 12px;
  width: 2em;
  text-align: center;

  &:hover {
    cursor: pointer;
    color: $primary;
  }
}

.lang:last-of-type {
  margin-left: 10px;
  padding: 0;
  &:hover {
    cursor: default;
  }
}

.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: color, transform;
}
</style>

<script>
const { loadLanguageAsync } = require('../../.nuxt/nuxt-i18n/utils');

const languageSelection = ['DE'];

export default {
  name: 'Language',

  data() {
    return {
      languages: [],
      open: false,
    };
  },

  created() {
    this.refreshView(this.$i18n.locale.toUpperCase());

    // if moment.js
    // this.$moment.locale(this.$i18n.locale);
  },

  methods: {
    close(val) {
      this.open = val;
      this.$emit('open', this.open);
    },

    async activate(newActiveLang) {
      // prepare data
      const oldLocale = this.$i18n.locale;
      const newLocale = newActiveLang.toLowerCase();
      const newPath = this.data.translatedURLs /* because of error pages */
        ? this.data.translatedURLs[newLocale]
        : this.$router.currentRoute.fullPath.replace(
          `/${oldLocale}/`,
          `/${newLocale}/`,
        );
      const messages = loadLanguageAsync(this.$i18n, newLocale);

      // update view
      this.refreshView(newActiveLang);

      // update data
      await messages;

      this.$store.commit('REQUIRE_REFRESH', true);

      this.$router.push({ path: newPath });
      this.$i18n.locale = newLocale;
      this.$i18n.onLanguageSwitched(oldLocale, newLocale);

      // if moment.js
      // this.$moment.locale(newLocale);

      setTimeout((_) => this.close(false), 2500);
    },

    refreshView(newActiveLang) {
      const newlanguages = [];
      for (const lang of languageSelection) {
        if (lang == newActiveLang) continue;
        newlanguages.push(lang);
      }
      newlanguages.push(newActiveLang);
      this.languages = newlanguages;
    },
  },

  computed: {
    data() {
      return this.$store.state.currentPage;
    },
  },
};
</script>
