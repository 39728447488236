<template>
  <div class="milchbuck-container">
    <div class="row no-gutters">
      <div class="col-lg-7">
        <img class="image-styles" v-lazy-load data-src="https://www.pool-informatik.ch/cams/cam1509.jpg" />
      </div>
      <div class="col-lg-5 bg-dark-white text-card d-flex align-items-center">
        <div class="margin-sides-text">
          <h1
            class="bold text-dark-brown mb-0"
            v-if="payload.title"
            v-html="payload.title"
          />
          <h3
            class="bold text-light-brown margin-subtitle"
            v-if="payload.untertitel"
            v-html="payload.untertitel"
          />
          <div v-if="payload.text" v-html="payload.text" />
        </div>
      </div>
    </div>
    <div class="bg-dark-white"></div>
  </div>
</template>

<script>
export default {
  props: {
    payload: { type: Object, default: Object },
    options: { type: Object, default: Object },
  },
};
</script>

<style lang="scss" scoped>
@import "~~include-media/dist/include-media";
.image-styles {
  height: 100%;
  object-fit: cover;
}
.margin-sides-text {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
  @include media(">=992px") {
    margin-left: 50px;
    margin-right: 35px;
  }
  @include media(">=1400px") {
    margin-left: 140px;
    margin-right: 125px;
  }
}
.margin-subtitle {
  margin-top: 5px;
  margin-bottom: 40px;
}
.text-card {
  border-radius: 0 5px 5px 0;
}
</style>
