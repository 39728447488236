<template>
  <div class="margin-section">
  <div class="main-container ">
    <h1
      class="bold margin-bottom-title text-center"
      v-if="payload.title"
      v-html="payload.title"
    />
    <div class="row">
      <div class="col-2 offset-lg-5 d-flex flex-column align-items-center">
        <div class="timeline-starting-point bg-dark-brown" />
        <div class="timeline bg-dark-brown" />
      </div>
    </div>
    <div v-if="payload.geschichtspunkte">
      <ZeitleisteGeschichteMeilenstein
        v-for="(step, index) in payload.geschichtspunkte"
        :key="step.title"
        :imgSrc="step.bild.normal"
        :title="step.title"
        :text="step.text"
        :datum="step.datum"
        :index="index"
        :textSide="(index + 1) % 2 === 0 ? 'right' : 'left'"
      />
    </div>
    <div class="row">
      <div
        class="col-2 col-lg-4 offset-lg-4 d-flex flex-column align-items-center"
      >
        <div class="timeline timeline-current" />
        <div
          class="timeline-starting-point bg-dark-brown d-lg-flex flex-column align-items-center"
        >
          <h3
            class="d-inline-block bold text-medium-gray mb-0 margin-top-end-text d-lg-none text-lg-center"
          >
            {{ payload.enddatum }}
          </h3>
        </div>
        <h3
          class="d-none bold text-medium-gray mb-0 margin-top-end-text d-lg-inline-block text-lg-center"
        >
          {{ payload.enddatum }}
        </h3>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import CheckSolid from "~/assets/icons/check-solid.svg?inline";

export default {
  props: { payload: { type: Object, default: Object } },
  components: {
    CheckSolid,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
.margin-top-end-text {
  margin-top: 20px;
}
.timeline {
  width: 4px;
  height: 70px;
  &-done {
    background-color: $dark-brown;
  }
  &-current {
    background: $dark-brown;
    height: 270px;
  }
  &-future {
    background: $dark-white;
    height: 270px;
    // margin-top: -200px;
  }
}
.timeline-top-line {
  width: 4px;
  height: 90px;
}
.timeline-starting-point {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.margin-bottom-title {
  margin-bottom: 100px;
}

.circle {
  width: 40px;
  height: 40px;
  border: 4px solid $dark-brown;
  border-radius: 50%;
  background: $medium-brown 0% 0% no-repeat padding-box;
  &-current {
    background: $dark-white 0% 0% no-repeat padding-box;
  }
  &-future {
    background: $white 0% 0% no-repeat padding-box;
    border: 4px solid $dark-white;
  }
}
.padding-icon {
  padding: 6px;
}
.negative-margin-circle {
  margin-top: -24px;
}
</style>
