<template>
  <div>
    <img class="image-styles" v-if="imgSrc" v-lazy-load :data-src="imgSrc" />
    <h3 class="bold margin-title">{{ title }}</h3>
    <div v-if="text" v-html="text" />
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: String,
    title: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.margin-title {
  margin-top: 50px;
  margin-bottom: 40px;
}
.image-styles {
  height: 400px;
  object-fit: cover;
}
</style>
