<template>
  <div class="row">
    <div
      class="d-none d-lg-block col-lg-5"
      :class="[{ 'order-lg-3': textSide === 'right' }]"
    >
      <img class="image-styles padding-side-text" v-if="imgSrc" v-lazy-load :data-src="imgSrc" />
    </div>
    <div
      class="col-2 d-flex flex-column align-items-center justify-content-center margin-side-timeline"
      :class="[{ 'order-lg-2': textSide === 'right' }]"
    >
      <div class="timeline timeline-top timeline-current" />
      <h3 class="bold text-dark-brown mb-0">{{ datum }}</h3>
      <div class="timeline timeline-bottom timeline-current" />
    </div>

    <div
      class="col-10 col-lg-5 d-flex flex-column justify-content-center padding-side-text"
      :class="[{ 'order-lg-1': textSide === 'right' }]"
    >
      <img class="d-block d-lg-none image-styles padding-side-text" v-if="imgSrc" v-lazy-load :data-src="imgSrc" />
      <h3 class="bold text-dark-brown">{{ title }}</h3>
      <div v-if="text" v-html="text" class="mb-lg-0 mb-4" />
    </div>
  </div>
</template>

<script>
import CheckSolid from "~/assets/icons/check-solid.svg?inline";

export default {
  props: {
    imgSrc: String,
    title: String,
    text: String,
    datum: String,
    index: Number,
    textSide: {
      type: String,
      default: "left",
    },
  },
  components: {
    CheckSolid,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "~~include-media/dist/include-media";
.image-styles {
  height: 400px;
  width: 100%;
  object-fit: cover;
  padding: 20px 0 20px 0;
  @include media("<=768px"){
    height: 200px;
  }
}
.timeline {
  width: 4px;
  height: 50%;
  position: relative;
  &-top {
    top: 0;
  }
  &-bottom {
    bottom: 0;
  }
  // margin-top: 0px;
  @include media(">=992px") {
    // margin-top: -400px;
  }
  z-index: -1;
  &-done {
    background-color: $dark-brown;
  }
  &-current {
    background: $dark-brown;
  }
  &-future {
    background: $dark-white;
  }
  // &-0 {
  //   height: 50%;
  //   top: 0;
  // }
}
.padding-icon {
  padding: 6px;
}
.circle {
  width: 40px;
  height: 40px;
  border: 4px solid $dark-brown;
  border-radius: 50%;
  background: $medium-brown 0% 0% no-repeat padding-box;
  &-current {
    background: $dark-white 0% 0% no-repeat padding-box;
  }
  &-future {
    background: $white 0% 0% no-repeat padding-box;
    border: 4px solid $dark-white;
  }
}
.padding-side-text {
  //   max-width: 400px;
  //   margin: auto;
}
.timeline-top-line {
  width: 4px;
  height: 90px;
}
.margin-side-timeline {
  margin-left: auto;
  margin-right: auto;
}
.image-styles {
}
</style>
