<template>
  <div class="col-3 position-relative">
    <!-- <div class="padding-left-circle">
      <p class="bold">{{ title }}</p>

      <div class="circle negative-margin-circle" :class="['circle-' + status]">
        <CheckSolid
          class="padding-icon"
          :class="{ 'd-none': status != 'done' }"
        />
      </div>
    </div> -->

    <div
      ref="wrapper"
      class="position-absolute milestone-teaser milestone-teaser-wrapper"
      :style="positioning"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="milestone-teaser milestone-teaser-background">
        <img
          ref="image"
          class="box-image-styles padding-side-text milestone-teaser milestone-teaser-image"
          v-if="imgSrc"
          v-lazy-load :data-src="imgSrc"
        />
        <div class="text-box-padding milestone-teaser milestone-teaser-textbox">
          <p ref="title" class="bold milestone-teaser milestone-teaser-title">
            {{ title }}
          </p>
          <div
            class="position-relative milestone-teaser milestone-teaser-text text-white"
            v-html="text"
          />
        </div>
      </div>
      <div class="arrow-down margin-left-arrow-down"></div>
    </div>

    <!-- <div class="milestone-teaser">
      <div class="bg-medium-brown text-box-size">
        <img
          class="box-image-styles padding-side-text show-on-hover"
          v-if="imgSrc"
          v-lazy-load :data-src="imgSrc"
        />
        <div class="text-box-padding">
          <p class="bold text-white">{{ title }}</p>
          <div class="text-white show-on-hover" v-html="text" />
        </div>
      </div>
      <div class="arrow-down margin-left-arrow-down"></div>
    </div> -->

    <!-- <div v-if="hover" class="position-absolute">
        <div class="bg-medium-brown text-box-size">
          <img
            class="box-image-styles padding-side-text"
            v-if="imgSrc"
            v-lazy-load :data-src="imgSrc"
          />
          <div class="text-box-padding">
            <p class="bold text-white">{{ title }}</p>
            <div class="text-white" v-html="text" />
          </div>
        </div>
        <div class="arrow-down margin-left-arrow-down"></div>
      </div>
      <div v-else>
        <p class="padding-left-cicle bold">{{ title }}</p>
      </div> -->
    <div class="padding-left-cicle">
      <div class="circle negative-margin-circle" :class="['circle-' + status]">
        <CheckSolid
          class="padding-icon"
          :class="{ 'd-none': status != 'done' }"
        />
      </div>
      <div class="horizontal-line" :class="['horizontal-line-' + status]" />
    </div>
  </div>

  <!-- <div class="row">
      <div
        class="d-none d-lg-block col-lg-5"
        :class="[{ 'order-lg-3': textSide === 'right' }]"
      >
        <img
          class="image-styles padding-side-text"
          v-if="imgSrc"
          v-lazy-load :data-src="imgSrc"
        />
      </div>
      <div
        class="col-2 d-flex flex-column align-items-center justify-content-center margin-side-timeline"
        :class="[{ 'order-lg-2': textSide === 'right' }]"
      >
        <div class="timeline timeline-top" :class="['timeline-' + status]" />
        <div
          class="circle negative-margin-circle"
          :class="['circle-' + status]"
        >
          <CheckSolid
            class="padding-icon"
            :class="{ 'd-none': status != 'done' }"
          />
        </div>
        <div
          class="timeline timeline-bottom"
          :class="['timeline-' + futureStatus]"
        />
      </div>

      <div
        class="col-10 col-lg-5 d-flex flex-column justify-content-center padding-side-text"
        :class="[{ 'order-lg-1': textSide === 'right' }]"
      >
        <h2 class="bold margin-title text-dark-brown">{{ title }}</h2>
        <div v-if="text" v-html="text" />
      </div>
    </div> -->
</template>

<script>
import CheckSolid from "~/assets/icons/check-solid.svg?inline";

export default {
  data() {
    return {
      top: 0,
      hover: false,
      mounted: false,
    };
  },
  props: {
    imgSrc: String,
    title: String,
    text: String,
    status: String,
    futureStatus: String,
    index: Number,
    textSide: {
      type: String,
      default: "left",
    },
  },
  computed: {
    positioning() {
      return {
        top: `-${this.top}px`,
      };
    },
  },
  components: {
    CheckSolid,
  },
  mounted() {
    // We want to position the title so it is above the circular check mark
    this.top = this.calculateTitlePosition();
  },
  watch: {
    hover() {
      let top = this.calculateTitlePosition();

      if (this.hover) {
        const wrapperHeight = this.$refs.wrapper.clientHeight;
        top = wrapperHeight;
      }

      this.top = top;
    },
  },
  methods: {
    calculateTitlePosition() {
      const titleHeight = this.$refs.title.clientHeight;
      const paddingHeight = 20;
      const imageHeight = this.$refs.image ? this.$refs.image.clientHeight : 0;

      return imageHeight + titleHeight + paddingHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "~~include-media/dist/include-media";
.show-on-hover {
  display: none;
}
.margin-left-arrow-down {
  margin-left: 32px;
}
.padding-left-cicle {
  padding-left: 20px;
}
.arrow-down {
  transition: all 500ms ease-in-out;
  visibility: hidden;
  display: none;
  width: 0;
  height: 18px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;

  border-top: 18px solid $medium-brown;
}

.milestone-teaser {
  transition: all 500ms ease-in-out;

  &-wrapper {
    max-width: 300px;
    overflow-y: hidden;
    position: relative;
    top: 0;

    &:hover {
      transform: translateY(0) !important;
    }
  }

  &-text,
  &-image {
    display: none;
    visibility: hidden;
  }

  &:hover {
    .milestone-teaser-background {
      background-color: $medium-brown !important;
    }

    .milestone-teaser-title {
      color: $white !important;
    }

    .milestone-teaser-text,
    .milestone-teaser-image,
    .arrow-down {
      display: block;
      visibility: visible;
    }

    .show-on-hover {
      display: block !important;
    }
  }
}
.horizontal-line {
  width: 100%;
  height: 4px;
  position: relative;
  bottom: 20px;
  z-index: -1;
  &-done {
    background-color: $dark-brown;
  }
  &-current {
    background: $dark-brown;
  }
  &-future {
    background: $dark-white;
  }
}
.circle {
  width: 40px;
  height: 40px;
  border: 4px solid $dark-brown;
  border-radius: 50%;
  background: $medium-brown 0% 0% no-repeat padding-box;
  &-current {
    background: $dark-white 0% 0% no-repeat padding-box;
  }
  &-future {
    background: $white 0% 0% no-repeat padding-box;
    border: 4px solid $dark-white;
  }
}
.text-box-padding {
  padding: 20px 30px;
}
.text-box-size {
  max-width: 300px;
}
.box-image-styles {
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.image-styles {
  height: 400px;
  width: 100%;
  object-fit: cover;
  padding: 20px 0 20px 0;
}
.timeline {
  width: 4px;
  height: 50%;
  position: relative;
  &-top {
    top: 0;
  }
  &-bottom {
    bottom: 0;
  }
  // margin-top: 0px;
  @include media(">=992px") {
    // margin-top: -400px;
  }
  z-index: -1;
  &-done {
    background-color: $dark-brown;
  }
  &-current {
    background: $dark-brown;
  }
  &-future {
    background: $dark-white;
  }
  // &-0 {
  //   height: 50%;
  //   top: 0;
  // }
}
.padding-icon {
  padding: 6px;
}

.padding-side-text {
  //   max-width: 400px;
  //   margin: auto;
}
.timeline-top-line {
  width: 4px;
  height: 90px;
}
.margin-side-timeline {
  margin-left: auto;
  margin-right: auto;
}
.margin-title {
  margin-bottom: 20px;
}
.image-styles {
}
</style>