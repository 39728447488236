<template>
  <div class="bg-dark-brown banner-card">
    <h1
      class="text-white bold text-center"
      v-if="payload.titel"
      v-html="payload.titel"
    />
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
@import "~~include-media/dist/include-media";
.banner-card {
  width: 100%;
  @include media(">=992px") {
    height: 470px;
    margin-bottom: -360px;
  }
}
</style>