<template>
  <div class="main-container row my-5">
    <div class="col-sm-6">
      <img v-lazy-load :data-src="payload.bildlinks.large">
      <span v-if="payload.untertitellinks">{{ payload.untertitellinks }}</span>
    </div>

    <div v-if="payload.bildrechts" class="col-sm-6">
      <img v-lazy-load :data-src="payload.bildrechts.large">
      <span v-if="payload.untertitelrechts">
        {{ payload.untertitelrechts }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      default: Object,
    },
    options: {
      type: Object,
      default: Object,
    },
  },
};
</script>

<style scoped>
div > img {
  padding-bottom: 10px;
}

</style>
