<template>
  <div class="margin-section">
    <div class="main-container">
      <h1 class="bold text-center mb-0" v-html="payload.title" />
      <div
        v-if="payload.haupttext"
        class="text-center mb-0 margin-top-main-text"
        v-html="payload.haupttext"
      />
      <div class="row margin-top-side-text">
        <div class="col-md-6">
          <div
            class="mb-0"
            v-if="payload.nebentext1"
            v-html="payload.nebentext1"
          />
        </div>
        <div class="col-md-6">
          <div
            class="mb-0"
            v-if="payload.nebentext2"
            v-html="payload.nebentext2"
          />
        </div>
      </div>
    </div>

    <!-- <div class="custom-container custom-bottom-margin">
      <div class="text-dark-blue custom-margin h1" v-html="payload.title" />
      <div
        v-if="payload.title2"
        class="h1 text-dark-blue custom-margin"
        v-html="payload.title2"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    payload: { type: Object, default: Object },
    options: { type: Object, default: Object },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "~~include-media/dist/include-media";

.margin-top-main-text {
  margin-top: 43px;
}
.margin-top-side-text {
  margin-top: 0px;
  @include media(">=768px") {
    margin-top: 130px;
  }
}
</style>
