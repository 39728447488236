// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.margin-komponente[data-v-076e7fde]{margin-top:200px;margin-bottom:200px}.image-styles[data-v-076e7fde]{max-height:208px}@media (min-width:1920px){.main-container-adjustments[data-v-076e7fde]{padding:0 140px!important}}.card-styling[data-v-076e7fde]{border-radius:10px;padding:74px 0}.banner-styling[data-v-076e7fde]{width:540px;height:80px;background:#bebebe 0 0 no-repeat padding-box;border-radius:10px;margin-bottom:-40px}", ""]);
// Exports
module.exports = exports;
