// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.language[data-v-105570e8]{color:#fff;position:relative;display:inline-block;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:1.5em;transform:translateY(-8px)}@media (max-width:991.98px){.language[data-v-105570e8]{opacity:0;max-width:0;margin-left:0}.active .language[data-v-105570e8]{opacity:1;max-width:150px;margin-left:15px}}.language:hover .lang[data-v-105570e8]:last-of-type,.language:hover svg[data-v-105570e8]{color:#007bff;cursor:pointer}.language.open[data-v-105570e8]{width:108px}.language.open .lang[data-v-105570e8]:last-of-type,.language.open svg[data-v-105570e8]{color:#007bff}.language.open .selection[data-v-105570e8]{width:108px}svg[data-v-105570e8]{position:relative;z-index:10}.selection[data-v-105570e8]{margin-right:-29.5px;transform:translateY(7.5px);overflow:hidden;width:25px;direction:rtl;transition:width .4s}.selection[data-v-105570e8],ul[data-v-105570e8]{display:inline-block}ul[data-v-105570e8]{width:108px;direction:ltr;margin:0;padding:0;transform:translateY(-8px)}.lang[data-v-105570e8]{display:inline-block;font-feature-settings:tabular-nums lining-nums;font-variant-numeric:tabular-nums lining-nums;padding:0 5px;font-size:12px;width:2em;text-align:center}.lang[data-v-105570e8]:hover{cursor:pointer;color:#007bff}.lang[data-v-105570e8]:last-of-type{margin-left:10px;padding:0}.lang[data-v-105570e8]:last-of-type:hover{cursor:default}.list-move[data-v-105570e8]{transition:.5s cubic-bezier(.59,.12,.34,.95);transition-property:color,transform}", ""]);
// Exports
module.exports = exports;
