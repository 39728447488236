<template>
  <div>
    <div
      class="bg-dark-brown banner-card d-flex align-items-center justify-content-center"
    >
      <h1
        class="text-white bold text-center"
        v-if="payload.title"
        v-html="payload.title"
      />
    </div>
    <div class="bg-dark-white">
      <div class="main-container">
        <div class="padding-section">
          <swiper
            v-if="payload.news"
            class="swiper margin-top-swiper"
            :options="swiperOption"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(step, index) in payload.news"
              :key="step.title"
            >
              <router-link :to="step.data.link" tag="div" role="button">
                <Artikel-Liste-Artikel
                  :imgSrc="step.data.bild.normal"
                  :title="step.data.title"
                  :text="step.data.text"
                  :datum="step.data.datum"
                  :index="index"
                />
              </router-link>
            </swiper-slide>
          </swiper>
          <div
            v-if="payload.news.length > 3"
            class="w-100 d-flex justify-content-center pagination-container"
          >
            <div
              class="swiper-prev d-flex justify-content-center align-items-center"
            >
              <ChevronLeft class="icon-styles" />
            </div>
            <div
              class="swiper-next d-flex justify-content-center align-items-center"
            >
              <ChevronRight class="icon-styles" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronRight from "~/assets/icons/chevron-right-solid.svg?inline";
import ChevronLeft from "~/assets/icons/chevron-left-solid.svg?inline";

export default {
  props: { payload: { type: Object, default: Object } },
  data() {
    return {
      swiperOption: {
        init: this.payload.news.length > 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        resistanceRatio: this.payload.news.length <= 3 ? 0 : 0.85,
        slidesPerView: 1,
        breakpoints: {
          992: {
            slidesPerView: 3,
          },
        },
        spaceBetween: 90,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      },
    };
  },
  components: {
    ChevronRight,
    ChevronLeft,
  },
};
</script>

<style lang="scss" scoped>
@import "~~include-media/dist/include-media";
.banner-card {
  width: 100%;
  @include media(">=992px") {
    height: 200px;
  }
}
.icon-styles {
  width: 14px;
  height: 14px;
}
.padding-title {
  padding-bottom: 30px;
}
.padding-button {
  padding-top: 80px;
}
</style>
