<template>
  <div class="bg-dark-white">
    <div class="main-container">
      <div class="row justify-content-between padding-section">
        <div
          class="col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-center align-items-center align-items-lg-start"
        >
          <img class="image-styles" v-lazy-load :data-src="payload.bild.normal">
        </div>
        <div class="col-lg-6">
          <h2 class="bold mt-3 mt-lg-0" v-html="payload.title" />
          <div v-if="payload.text" class="mb-0" v-html="payload.text" />
          <div class="d-flex justify-content-center justify-content-lg-start">
            <nuxt-link
              v-if="payload.linkziel"
              :to="payload.linkziel"
              class="mt-3 btn-secondary d-flex align-items-center justify-content-center"
            >
              <h3 class="bold mb-0">
                {{ payload.buttontext }}
              </h3>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: { type: Object, default: Object },
    options: { type: Object, default: Object },
  },
};
</script>

<style lang="scss" scoped>
.image-styles {
  max-width: 540px;
}
</style>
