<template>
  <div>
    <div
      class="bg-dark-brown banner-card d-flex align-items-center justify-content-center"
      style="flex-direction: column;"
    >
      <h1
          class="text-white bold text-center"
          v-if="payload.title"
          v-html="payload.title"
      />
      <h2
          class="text-white bold text-center"
          v-if="payload.subtitle"
          v-html="payload.subtitle"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
@import "~~include-media/dist/include-media";
.banner-card {
  width: 100%;
  @include media(">=992px") {
    height: 200px;
  }
}
</style>
