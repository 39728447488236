<template>
  <div class="margin-section">
    <div class="main-container">
      <h2 class="bold text-center margin-bottom-title" v-html="payload.title" />
    </div>
    <div class="bg-dark-white">
      <div class="main-container padding-pdf-viewer">
        <swiper ref="buchSwiper" class="swiper" :options="swiperOption">
          <swiper-slide
            class="swiper-slide"
            v-for="image in payload.seiten"
            :key="image.title"
          >
            <img :data-src="image.sizes.large" :alt="image.alt" class="swiper-lazy">
            <h3
              class="bold text-dark-brown mb-0 margin-top-caption"
              v-if="image.caption"
              v-html="image.caption"
            />
          </swiper-slide>
          <div
            class="image-swiper swiper-pagination d-none d-lg-block margin-top-pagination"
            slot="pagination"
          />
        </swiper>

        <div
          v-if="payload.seiten.length > 1"
          class="row d-flex justify-content-between pagination-container"
        >
          <div class="col-4 d-flex justify-content-start">
            <p
              class="bold text-dark-brown mb-0 align-self-center"
              v-if="payload.buchtitel"
              v-html="payload.buchtitel"
            />
          </div>
          <div class="col-4 d-flex justify-content-center">
            <div
              class="swiper-prev d-flex justify-content-center align-items-center"
            >
              <ChevronLeft class="icon-styles" />
            </div>
            <div
              class="swiper-next d-flex justify-content-center align-items-center"
            >
              <ChevronRight class="icon-styles" />
            </div>
          </div>
          <div class="col-4 d-flex justify-content-end">
            <p class="bold text-dark-brown mb-0 align-self-center">
              {{ $t("page") }} {{ activeIndex }}/28
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronRight from '~/assets/icons/chevron-right-solid.svg?inline';
import ChevronLeft from '~/assets/icons/chevron-left-solid.svg?inline';

export default {
  props: { payload: { type: Object, default: Object } },
  data() {
    return {
      swiper: undefined,
      swiperOption: {
        init: this.payload.seiten.length > 1,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        slidesPerView: 1,
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
        },
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },

        lazy: {
          loadPrevNext: true,
        },

      },
    };
  },
  computed: {
    activeIndex() {
      if (this.swiper) return this.swiper.activeIndex + 1;
    },
  },
  mounted() {
    this.swiper = this.$refs.buchSwiper.$swiper;
  },
  components: {
    ChevronRight,
    ChevronLeft,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "@/assets/scss/_mixins.scss";
@import "~~include-media/dist/include-media";
.pagination-container[data-v-6a5bac8c] {
  top: -6px !important;
}
.padding-pdf-viewer {
  padding-top: 52px;
  padding-bottom: 42px;
}
.margin-bottom-title {
  margin-bottom: 80px;
}
.margin-image-swiper {
  margin-top: 201px;
  margin-bottom: 182px;
}
.image-styles {
  width: 100%;
  height: 719px;
  object-fit: cover !important;
  @include media("<=768px") {
    height: 300px;
  }
}
.swiper {
  margin: 50px 0;
}
.margin-top-caption {
  margin-top: 30px;
  @include media(">=992px") {
    margin-top: 30px;
  }
}
.icon-styles {
  width: 14px;
  height: 14px;
}
.pagination-container {
  position: relative;
  top: 10px;
  z-index: 1000;
  @include media(">=1200px") {
    top: -40px;
  }
}
</style>
