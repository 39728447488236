<template>
  <div class="main-container margin-subpage mt-4">
    <div class="back-button" @click="hasHistory() ? $router.push('/de') : $router.back()">
      <div class="d-flex align-items-center">
        <ArrowMediumGrayBack class="mr-1" />
        <p class="bold text-dark-brown mb-0">
          {{ $t('buttons.back') }}
        </p>
      </div>
    </div>
    <h1 class="bold margin-title" v-if="payload.title" v-html="payload.title" />
    <p class="text-dark-gray" v-if="payload.datum" v-html="payload.datum" />
    <img v-if="payload.bild" v-lazy-load :data-src="payload.bild.large" class="image-styles">
    <div class="mt-3" v-if="payload.text" v-html="payload.text" />
  </div>
</template>

<script>
import ArrowMediumGrayBack from '~/assets/images/arrow-medium-gray-back.svg?inline';

export default {
  props: {
    payload: {
      type: Object,
      default: Object,
    },
    options: {
      type: Object,
      default: Object,
    },
  },
  components: {
    ArrowMediumGrayBack,
  },
  methods: {
    hasHistory() {
      return window.history.length <= 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "~~include-media/dist/include-media";

.image-styles {
  width: 100%;
  max-height: 300px;
  object-fit: cover !important;
}

.margin-title {
  margin-top: 50px;
}

.margin-subpage {
  margin-top: 50px;
  margin-bottom: 200px;
}

.custom-container-subpages {
  margin-bottom: 60px;
}

.component-top-margin {
  margin-top: 33px;
  @include media(">=992px") {
    margin-top: 66px;
  }
}

.title-top-margin {
  margin-top: 29px;
}

.back-button {
  cursor: pointer;
}
</style>
